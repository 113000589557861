<template>
  <v-form>
    <v-container>
      <template v-if="(data_asesoria.tipo == 0 ? false : true)">
        <div class="d-flex align-center flex-wrap justify-center justify-sm-start mb-6 bg-surface-variant">
          <v-sheet>
            <v-icon>{{ icons.mdiShareVariant }}</v-icon>
            Compartir
          </v-sheet>
          <v-sheet class="ma-2">
            <v-btn
              icon
              rounded
              color="indigo"
              :href="`https://www.facebook.com/sharer/sharer.php?u=${data_asesoria.link}`"
              target="_blank"
            >
              <v-icon>{{ icons.mdiFacebook }}</v-icon>
            </v-btn>
            <v-btn
              icon
              rounded
              color="teal"
              :href="`https://api.whatsapp.com/send?text=Únete a la asesoría en el siguiente enlace: ${data_asesoria.link}`"
              target="_blank"
            >
              <v-icon>{{ icons.mdiWhatsapp }}</v-icon>
            </v-btn>
          </v-sheet>
        </div>
      </template>
      <v-toolbar>
        <v-toolbar-title> {{ titulo }} </v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-btn color="success" :to="{name:'profesorinicio'}">Regresar</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="error"  @click="cancelar_asesoria_dialog">CANCELAR ASESORIA</v-btn>
      </v-toolbar>
      <v-card>  
        <v-row>
          <v-col cols="12" sm="8" md="3">
            <v-text-field
              readonly
              :value="(data_asesoria.tipo == 0 ? 'Individual' : data_asesoria.tipo == 1 ? 'Grupal' : 'Taller')"
              label="Tipo de Asesoria"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="8" md="3">
            <v-text-field
              readonly
              :value="data_asesoria.nombre"
              label="Curso"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="7" md="2">
            <v-text-field
              readonly
              :value="data_asesoria.fecha"
              label="Fecha"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="7" md="2">
            <v-text-field
              readonly
              :value="data_asesoria.hora"
              label="Hora Inicio"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="7" md="2">
            <v-text-field
              readonly
              :value="data_asesoria.hora_fin"
              label="Hora Fin"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>

      <v-divider
        class="mx-4"
        vertical
      ></v-divider>

      <div v-if="(data_asesoria.tipo == 0 ? false : true)">
        <v-text-field 
          outlined 
          label="Link" 
          persistent-hint 
          readonly
          hint="Link de la asesoria para que se unan mas alumnos"
          :value="data_asesoria.link"
        >
        </v-text-field>
      </div>

      <v-divider
        class="mx-4"
        vertical
      ></v-divider>

      <v-text-field 
        outlined 
        label="Lugar" 
        persistent-hint 
        hint="Cambie el lugar de ser necesario"
        v-model="data_asesoria.lugar"
      ></v-text-field>

      <v-spacer></v-spacer>
      
      <v-layout column allign-center>
        <v-row justify="center">
          <v-btn color="error" @click="actualizar_lugar">Enviar Link</v-btn>
        </v-row>
      </v-layout>
      <v-spacer></v-spacer>


      <v-divider
        class="mx-4"
        vertical
      ></v-divider>

      <v-layout column allign-center>
        <v-row align="center"
          justify="center">
          <v-col align="center"
          justify="center" cols="12" sm="8" md="2">
            <p><b>Alumno</b></p>
          </v-col>
          <v-col align="center"
          justify="center" cols="12" sm="8" md="2">
            <p><b>Check Asistencia</b></p>
          </v-col>
          <v-col align="center"
          justify="center" cols="12" sm="8" md="2">
            <p><b>Tipo de pago</b></p>
          </v-col>
          <v-col align="center"
          justify="center" cols="12" sm="8" md="2">
            <p><b>Estado de pago</b></p>
          </v-col>
          <v-col align="center"
          justify="center" cols="12" sm="8" md="2">
            <p><b>Check Pago</b></p>
          </v-col>
          <v-col align="center"
          justify="center" cols="12" sm="8" md="2">
            <p><b>Monto</b></p>
          </v-col>

        </v-row>

        <v-row align="center"
          justify="center" v-for="(item, i) in data_alumnos" :key="i" >
          <v-col align="center"
          justify="center" cols="12" sm="8" md="2">
            <span>{{item.nombre}}</span>
          </v-col>


          <v-col align="center" justify="center" cols="12" sm="8" md="2">

            <v-checkbox v-if="item.asistencia == 1" class="align-center justify-center"
              input-value="true"
              label="¿Asistió?"
              disabled
            ></v-checkbox>

            <v-checkbox  v-else class="align-center justify-center"
              v-model="asistenciaAlumnos"
              label="¿Asistió?"
              :value="item.id"
              hide-details
            ></v-checkbox>

          </v-col>



          <v-col align="center" justify="center" cols="12" sm="8" md="2">
            <span>{{item.tipo_pago}}</span>
          </v-col>
          <v-col align="center"
          justify="center" cols="12" sm="8" md="2">
            <span v-if="item.estado_pago == 1" >Pagado</span>
            <span v-else>Pendiente</span>
          </v-col>


          <v-col align="center" justify="center" cols="12" sm="8" md="2">

            <v-checkbox v-if="item.estado_pago == 1" class="align-center justify-center"
              input-value="true"
              label="¿Pago?"
              disabled
            ></v-checkbox>

            <v-checkbox  v-else class="align-center justify-center"
              v-model="pagoAlumnos"
              label="¿Pago?"
              :value="item.id"
              hide-details
            ></v-checkbox>

          </v-col>


          <v-col align="center"
          justify="center" cols="12" sm="8" md="2">
            <span>S/. {{item.por_cobrar}}.00</span>
          </v-col>
          
        </v-row>
        <v-row align="center" justify="center">
          <v-btn color="success" :loading="isLoading" @click="actualizar_datos">ACTUALIZAR DATOS</v-btn>
        </v-row>
      </v-layout>

      <v-dialog v-model="msgbox" persistent width="600px">
        <v-card>
          <v-card-title class="headline" v-text="msgtitulo"></v-card-title>
          <v-card-text v-text="msgtexto"></v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="success" text @click="msgbox = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

       <v-dialog v-model="dialog_cancelar_asesoria" persistent width="600px">
        <v-card>
          <v-card-title class="headline" v-text="cancelar_asesoria_title"></v-card-title>
          <v-card-text class="text--primary" color="black" v-text="msgwarn"></v-card-text>
          <v-card-text class="text--primary" color="black" v-text="msgtexto"></v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="success" text @click="cerrar_mensaje">Cancelar</v-btn>
            <v-btn color="success" text @click="cancelar_asesoria">Si</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-container>
  </v-form>
</template>

<script>
import { axiosInstance } from "../../service/index";
import {mapState, mapMutations} from 'vuex'
import {cancelarAsesoriaProfe, updateAsistencia } from '../../service/profesorService'
import {
    mdiWhatsapp,
    mdiShareVariant,
    mdiFacebook
} from '@mdi/js'

export default {
  data () {
    return {
      icons: {
        mdiWhatsapp,
        mdiShareVariant,
        mdiFacebook
      },
      titulo: '---',
      msgbox: false,
      msgtitulo: '',
      msgtexto: '',
      dialog_cancelar_asesoria: false,
      msgwarn: '',
      cancelar_asesoria_title: '¿Estás Seguro?',
      isLoading: false,
      asistenciaAlumnos: [],
      pagoAlumnos: [],
      
      data_asesoria: {},
      data_alumnos: [],
      headers: [
        {text: 'Alumno', value: 'nombre'},
        {text: 'Tipo de Pago', value: 'tipo_pago'},
        {text: 'Estado del Pago', value: 'estado_pago'},
        {text: 'Por Cobrar', value: 'por_cobrar'}
        
      ]
    }
  },
  created () {
    this.obtener_asesoria()
  },
  computed: {
    ...mapState([
      'currentJWT',
      'userProfesor',
      'id_Asesoria_profe',
    ]),
  },
  methods:{
    obtener_asesoria(){
      var me = this
      var auxURL = '/api/asesoria/verAsesoriaByProfesor?id_asesoria=' + me.id_Asesoria_profe + '&id_profesor=' + me.currentJWT.id + '&token=' + me.currentJWT.token
      axiosInstance.get(auxURL)
        .then(res => {
          me.data_asesoria = res.data[0];
          me.data_asesoria.link = this.$hostname + "/alumno/unirse_asesoria/" + me.id_Asesoria_profe;
          me.data_alumnos = res.data[1];
          me.titulo = (me.data_asesoria.tipo == 3? 'Datos del Taller' : 'Datos de la Asesoria');
        }).catch(err =>{
          me.msgtitulo = 'Error:';
          console.log(err)
          me.msgtexto = err.data;
          me.msgbox = true;
        });
    },
    actualizar_lugar(){
      var me = this
      var auxURL = '/api/asesoria/updateLugarAsesoria?id_asesoria=' + me.id_Asesoria_profe + '&lugar=' + me.data_asesoria.lugar + '&id_profesor=' + me.currentJWT.id + '&token=' + me.currentJWT.token
      axiosInstance.get(auxURL)
        .then(res => {
          me.msgtitulo = 'Actualización Realizada';
          me.msgtexto = 'El Lugar fue actualizado correctamente';
          me.msgbox = true;
        }).catch(err =>{
          me.msgtitulo = 'Error:';
          me.msgtexto = err.data;
          me.msgbox = true;
        });
    },
    async actualizar_datos() {
      this.isLoading = true;
      let me = this
      let idAlum = -1
      let pago = 0
      let asistencia = 0
      let data = [];
      for (let i = 0; i<me.data_alumnos.length; i++ ) {
        idAlum = me.data_alumnos[i].id;
        asistencia = 0;
        pago = 0;

        //ASISTENCIA DE ALUMNOS
        for(let j =0; j<me.asistenciaAlumnos.length; j++){
          if(idAlum == me.asistenciaAlumnos[j]){
            asistencia = 1;
            break;
          }
        }

        //PAGO DE ALUMSNO
        for(let j =0; j<me.pagoAlumnos.length; j++){
          if(idAlum == me.pagoAlumnos[j]){
            pago = 1;
            break;
          }
        }
        data.push({
          id: idAlum,
          pago,
          asistencia
        });
      }
      let respuesta = await updateAsistencia(me.id_Asesoria_profe, data);
      this.isLoading = false;
      if (respuesta.success) {
        me.msgtitulo = 'Actualización Realizada';
        me.msgtexto = respuesta.message;
        me.msgbox = true;
      } else {
        me.msgtitulo = 'Error:';
        me.msgtexto = respuesta.message;
        me.msgbox = true;
      }
    },
    cancelar_asesoria_dialog(){
      this.dialog_cancelar_asesoria = true;
    },
    cerrar_mensaje(){
      this.dialog_cancelar_asesoria = false;
    },
    async cancelar_asesoria(){
      const today = new Date();
      const asesoriaFechaInicio = new Date(`${this.data_asesoria.fecha} ${this.data_asesoria.hora}`);
      if (asesoriaFechaInicio > today) {
        const diffTime = Math.abs(asesoriaFechaInicio - today);
        const diffHrs = (diffTime / (1000 * 60 * 60));
        if (diffHrs > 2) {
          let respuesta = await cancelarAsesoriaProfe(this.id_Asesoria_profe, this.currentJWT.id, this.currentJWT.token)
          if (respuesta.operacion) {
            if (respuesta.data == 'Hay alumnos en la asesoria') {
              this.cancelar_asesoria_title = 'No se puede cancelar asesoria porque hay otros alumnos';
            } else {
              this.cancelar_asesoria_title = 'Se canceló la asesoría con éxito';
              this.$router.push({ name:'profesorinicio' }) 
            }
            this.cerrar_mensaje();
          } else {
            this.$store.state.errobj.errtitulo = respuesta.msgtitulo
            this.$store.state.errobj.errtexto = respuesta.msgtexto
            this.$store.state.errobj.errbox = respuesta.msgbox
          }
        } else {
          swal("Error", "Lo sentimos, no se puede cancelar la Asesoría faltando menos de 2 horas para que se realice.", "error");
        }
      } else {
        swal("Error", "Lo sentimos, no se puede cancelar la Asesoría porque ya se ha realizado", "error");
      }
    }
  }
}
</script>

